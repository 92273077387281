import React from "react";
import {
    Box,
    Flex,
    Heading,
    Link,
} from '@chakra-ui/react'
import NavMenu from "./NavMenu";
import { Link as GatsbyLink } from "gatsby";
import MobileNavMenu from "./NavMenus/MobileNavMenu";

const StickyHeader = () => {
    return (
            <>
                <Box bgColor="#134053" as="header" display={{base: 'none', lg: 'block'}} className="static-header" zIndex="1000">
                    {/* <IconBar /> */}
                    <Flex w="100%" p="4" justifyContent="space-between" alignItems="center">
                        <Box w="40%" pt="0.4rem">
                            <Link as={GatsbyLink} to="/">
                                <Heading fontSize="2.125rem" textTransform="uppercase" color="white" _hover={{color: 'happy-yellow.500'}}>City Of Driggs</Heading>
                            </Link>
                        </Box>
                        <Box w="60%" color="white">
                            <NavMenu />
                        </Box>
                    </Flex>
                </Box>
                <MobileNavMenu />
            </>
    )
}

export default StickyHeader;