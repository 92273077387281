import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import './layout.css'
import theme from '../theme'
import StaticHeader from './Header/StaticHeader'
import Footer from './Footer/Footer'

if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]')
}

const InternalLayout = (props) => {
    return (
        <ChakraProvider resetCSS theme={theme}>
                <div className="site">
                    <StaticHeader />
                    <main>
                        <div>{props.children}</div>
                    </main>
                    <Footer />
                </div>
        </ChakraProvider>
    )
}

export default InternalLayout;